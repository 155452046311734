import React from 'react'

export default function Banner() {
  return (
    <div className="banner_area" style={{ backgroundImage: "url('/assets/images/banner_1.png')",backgroundSize:'cover' }}>
      <div className="sec_container">
      <div className="banner_text">
<h1>
Shri Ram Mandir Janmabhumi Ayodhya
</h1>
<span>
  <img src="/assets/images/green_map.png" alt="" />
Ayodhya (UP)
</span>
      </div>
      </div>
    </div>
  )
}

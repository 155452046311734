import React from 'react'
import NavBar from './NavBar';
import SignUpButton from './SignUpButton'

export default function Header() {
  return (
    <header className='header_bar'>
      <div className="sec_container">
        <div className="header_inner">
      <div className="logo">
        <img src="/assets/images/logo.png" alt="" />
      </div>
      <div className="navbar_outer nav_desk">
        <NavBar/>
      </div>
      

      <SignUpButton/>
      </div>
      </div>
    </header>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='footer_outer'>
        <div className="sec_container">
            <div className="footer_inner">
                <div className="foot_top">
<div className="foot_left">
<div className='foot_logo_outer'>
<img src="/assets/images/footer_logo.png" alt="" />
<ul>
    <li>
        <img src="/assets/images/phone_icon.png" alt="" />
        <span>+971562735617</span>
    </li>
    <li>
        <img src="/assets/images/map_icon_1.png" alt="" />
        <span>India  : 408, ABC Tower, Sector 135, Greater Noida, UP</span>
    </li>
</ul>
</div>
<p>
Lorem ipsum dolor sit amet consectetur. Tellus congue et libero purus tincidunt. Aenean turpis vel cras elementum ante. Tristique nunc velit in malesuada amet eget sit Lorem ipsum dolor sit amet consectetur. Tellus congue et libero purus tincidunt. Aenean turpis vel cras elementum ante. Tristique nunc velit in malesuada amet eget sit Lorem ipsum dolor sit amet consectetur. Tellus congue et libero purus tincidunt. Aenean turpis vel cras elementum ante.
</p>
</div>
<div className="foot_right">
    <div className=' foot_nav'>
<div>
    <span>Get In Touch</span>
    <ul>
        <li>
            <Link>
            <img src="/assets/images/whatsapp_icon1.png" alt="" />
            +971562735617
            </Link>
        </li>
        <li>
            <Link>
            <img src="/assets/images/skype_icon.png" alt="" />
            +971562735617
            </Link>
        </li>
        <li>
            <Link>
            <img src="/assets/images/message_icon.png" alt="" />
            +971562735617
            </Link>
        </li>
        <li>
            <Link>
            <img src="/assets/images/mail.png" alt="" />
            +971562735617
            </Link>
        </li>
    </ul>
</div>
<div>
    <span>Services</span>
    <ul>
        <li>
            <Link>Rent a Locker</Link>
        </li>
        <li>
            <Link>Yatri Pack</Link>
        </li>
        <li>
            <Link>Wishlist</Link>
        </li>
        <li>
            <Link>My Plan</Link>
        </li>
        <li>
            <Link>VR Darshan</Link>
        </li>
        <li>
            <Link>Blogs</Link>
        </li>
    </ul>
</div>
<div>
    <span>Services</span>
    <ul>
        <li>
            <Link>Book Pandit Ji</Link>
        </li>
        <li>
            <Link>Cab Rentals</Link>
        </li>
        <li>
            <Link>Auto Ride</Link>
        </li>
        <li>
            <Link>Boat Ride</Link>
        </li>
        <li>
            <Link>Hotel Booking</Link>
        </li>
    </ul>
</div>
    </div>
</div>
                </div>
                <div className="foot_bottom">
                    
                        <ul className="terms">
                            <li>
                                <Link>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link>Terms & Conditions</Link>
                            </li>
                        </ul>
                    
                    <div className="copyright">
                    Copyright 2024 © Mandirwiki
                    </div>
                    <ul className="social_icons">
                        <li>
                            <Link>
                            <img src="/assets/images/fb_icon.png" alt="" />
                            </Link>
                        </li>
                        <li>
                            <Link>
                            <img src="/assets/images/linkedin_icon.png" alt="" />
                            </Link>
                        </li>
                        <li>
                            <Link>
                            <img src="/assets/images/twitter_icon.png" alt="" />
                            </Link>
                        </li>
                        <li>
                            <Link>
                            <img src="/assets/images/insta_icon.png" alt="" />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

import React from 'react'
import Header from '../components/Header'
import Banner from '../components/Banner'
import FaqSlider from '../components/FaqSlider'
import Footer from '../components/Footer'
export default function HomePage() {
  return (
    <div className='home_wrapper'>
      <Header/>
      <Banner/>
      {/* discover start */}
      <div className="discover_section">
        <div className="sec_container">
          <div className="discover_inner">
        <div className="heading">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        </div>
        <div className="search_bar">
          <input type="text" placeholder='Discover a Temple or City' />
          <img src="/assets/images/search_icon.png" alt="" />
        </div>
<ul className="temples_list">
  <li>
    <img src="/assets/images/mandir_1.png" alt="" />
    <span>Shree Ram Mandir</span>
  </li>
  <li>
    <img src="/assets/images/mandir_2.png" alt="" />
    <span>Kedarnath Dham</span>
  </li>
  <li>
  <img src="/assets/images/mandir_3.png" alt="" />
    <span>Shree Mata Vaishno Devi</span>
  </li>
  <li>
  <img src="/assets/images/mandir_4.png" alt="" />
    <span>Kashi Vishwanath</span>
  </li>
  <li>
  <img src="/assets/images/mandir_5.png" alt="" />
    <span>Madurai Meenakshi</span>
  </li>
</ul>

        </div>
        </div>

      </div>
      {/* discover ends */}
      {/* temple details home start */}
      <div className="shree_mata_outer">
        <div className="sec_container">
          <div className="shree_mata_inner">
            <div>
              <img src="/assets/images/shree_mata_img.png" alt="" />
            </div>
            <div>
              <span className='title'>Temple Details</span>
              <div className='heading'>Shree Mata Vaishno Devi Temple</div>
              <p><b>Vaishno Devi Temple</b>, also known as the Shri Mata Vaishno Devi Temple and Vaishno Devi Bhavan, is a Hindu mandir (temple) located in Katra, Jammu and Kashmir, dedicated to Vaishno Devi, a manifestation of Durga.</p>
              <ul className="temple_details_data">
                <li>
                  <b>
                  Temple Timings
                  <img src="/assets/images/shree_icon_1.png" alt="" />
                  </b>
                  <p>{'06:00 AM to 01:00 PM'}<br/>
                      {'04:00 PM to 08:00 PM'}
                  </p>
                  
                </li>
                <li>
<b>
  Diety
  <img src="/assets/images/shree_icon_2.png" alt="" />
</b>
<span>Vaishno Devi</span>

                </li>
                <li>
                  <b>
                  Location
                  <img src="/assets/images/shree_icon_3.png" alt="" />
                  </b>
                  <span>Jammu & Kashmir</span>
                </li>
                <li>
                  <b>
                  Nearest Airport
                  <img src="/assets/images/shree_icon_4.png" alt="" />
                  </b>
                  <span>
                  Jammu Airport (IXJ)
                  </span>
                </li>
                <li>
                  <b>
                  Nearest Railway Station
                  <img src="/assets/images/shree_icon_5.png" alt="" />
                  </b>
                  <span>Shree Mata Vaishno Devi Katra (SVDK)</span>
                </li>
                <li>
                  <b></b>
                  <span>View all</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* temple details home ends */}
      {/* our services start */}
      <div className="our_services_outer">
        <div className="sec_container">
          <div className="our_services_inner">
            <div className="title">Our Services</div>
            <div className="heading">
            Services We Serve
            </div>
            <p>Lorem ipsum dolor sit amet consectetur. Tellus congue et libero purus tincidunt. Aenean turpis vel cras elementum ante. Tristique nunc velit in malesuada amet eget sit Lorem ipsum dolor sit amet consectetur.</p>
            <ul>
              <li>
                <img src="/assets/images/service_1.png" alt="" />
                <span>Scan QR</span>
              </li>
              <li>
                <img src="/assets/images/service_2.png" alt="" />
                <span>Rent a Locker</span>
              </li>
              <li>
                <img src="/assets/images/service_3.png" alt="" />
                <span>Yatri Pack</span>
              </li>
              <li>
                <img src="/assets/images/service_4.png" alt="" />
                <span>Wishlist</span>
              </li>
              <li>
                <img src="/assets/images/service_5.png" alt="" />
                <span>My Plans</span>
              </li>
              <li>
                <img src="/assets/images/service_6.png" alt="" />
                <span>VR Darshan</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* our services ends */}
      {/* solutions start */}
      <div className="solutions_outer">
        <div className="sec_container">
          <div className="solutions_inner">
            <div className="title">Solutions</div>
            <div className="heading">Our Travelling Solutions</div>
            <p>
            Lorem ipsum dolor sit amet consectetur. Tellus congue et libero purus tincidunt. Aenean turpis vel cras elementum ante. Tristique nunc velit in malesuada amet eget sit Lorem ipsum dolor sit amet consectetur.
            </p>
            <ul>
              <li>
                <span>
                  <img src="/assets/images/travelling_1.png" alt="" />
                </span>
                Flight Booking
              </li>
              
              <li>
                <span>
                  <img src="/assets/images/travelling_2.png" alt="" />
                </span>
                Cab Rentals
              </li>
              <li>
                <span>
                  <img src="/assets/images/travelling_3.png" alt="" />
                </span>
                Auto Ride
              </li>
              <li>
                <span>
                  <img src="/assets/images/travelling_4.png" alt="" />
                </span>
                Boat Ride
              </li>
              <li>
                <span>
                  <img src="/assets/images/travelling_5.png" alt="" />
                </span>
                Hotel Booking
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* solutions ends */}
      {/* Information start */}
      <div className="information_outer">
        <div className="sec_container">
          <div className="information_inner">
            <div className="title">Information</div>
            <div className="heading">We Provide Important Information</div>
            <p>
            Lorem ipsum dolor sit amet consectetur. Tellus congue et libero purus tincidunt. Aenean turpis vel cras elementum ante. Tristique nunc velit in malesuada amet eget sit Lorem ipsum dolor sit amet consectetur. Tellus congue et libero purus tincidunt. Aenean turpis vel cras elementum ante. Tristique nunc velit in malesuada amet eget sit.
            </p>
            <ul>
              <li>
                <img src="/assets/images/we_provide_1.png" alt="" />
                <span>Last Mile Connectivity</span>
              </li>
              <li>
                <img src="/assets/images/we_provide_2.png" alt="" />
                <span>Nearest Railway Station</span>
              </li>
              <li>
                <img src="/assets/images/we_provide_3.png" alt="" />
                <span>Parking Facilities</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Information ends */}
      {/* Faq start */}
      <div className="faqs_outer">
        <div className="title">Have aNY DOUBTS?</div>
        <div className="heading">Frequently Asked Questions</div>

        <FaqSlider/>
      </div>
      {/* Faq ends */}

      <Footer/>
    </div>
  )
}

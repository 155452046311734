import React from 'react'
import { Link } from 'react-router-dom'

export default function NavBar() {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/">About Us</Link>
        </li>
        <li>
          <Link to="/">
            Our Services 
            <img src="/assets/images/down_arrow.png" alt="" />
          </Link>
        </li>
        <li>
          <Link to="/">
            Other Services
          <img src="/assets/images/down_arrow.png" alt="" />
          </Link>
        </li>
        
      </ul>
    </nav>
  )
}
